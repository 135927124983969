var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"width":"1100"},model:{value:(_vm.onboardingDialog),callback:function ($$v) {_vm.onboardingDialog=$$v},expression:"onboardingDialog"}},[_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-card-title',{staticClass:"pb-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","large":"","color":"error"},on:{"click":function($event){return _vm.$store.dispatch('setOnboarding', false)}}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)],1),_c('v-window',{staticClass:"color-window-onboarding",attrs:{"show-arrows":""},scopedSlots:_vm._u([{key:`prev`,fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1)]}},{key:`next`,fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1)]}}],null,true),model:{value:(_vm.onboardingIndex),callback:function ($$v) {_vm.onboardingIndex=$$v},expression:"onboardingIndex"}},_vm._l((_vm.getOnboardingItems),function(onboardingItem,index){return _c('v-window-item',{key:`card-${index}`},[_c('v-card',{attrs:{"color":"transparent"}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[_c('v-card-text',{staticClass:"text-justify"},[_c('v-container',[(!onboardingItem.Components[_vm.currentLanguage])?_c('v-row',{staticClass:"mx-12 my-0"},[_c('v-col',{staticClass:"pr-6",attrs:{"cols":"6"}},[(_vm.getUserToken)?_c('v-img',{attrs:{"contain":"","height":"400","lazy-src":_vm.serverUrl +
                          '/images/onboarding/' +
                          onboardingItem.Images[_vm.currentLanguage] +
                          '?token=' +
                          _vm.getUserToken,"src":_vm.serverUrl +
                          '/images/onboarding/' +
                          onboardingItem.Images[_vm.currentLanguage] +
                          '?token=' +
                          _vm.getUserToken},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}):_vm._e()],1),_c('v-col',{staticClass:"pl-6",attrs:{"cols":"6"}},[_c('p',{domProps:{"innerHTML":_vm._s(onboardingItem.Texts[_vm.currentLanguage])}})])],1):_c(_vm.onboardingComponent,{tag:"component",staticClass:"mx-12 my-0"})],1)],1)],1)],1)],1)}),1),_c('v-card-actions',[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-item-group',{staticClass:"text-center",attrs:{"mandatory":""},model:{value:(_vm.onboardingIndex),callback:function ($$v) {_vm.onboardingIndex=$$v},expression:"onboardingIndex"}},_vm._l((_vm.getOnboardingItems),function(n,index){return _c('v-item',{key:`btn-${index}`,scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('v-btn',{attrs:{"color":"primary","input-value":active,"icon":"","x-small":""},on:{"click":toggle}},[_c('v-icon',[_vm._v("mdi-record")])],1)]}}],null,true)})}),1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }